var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "EquipmentsListView",
      attrs: { "data-test-id": "equipments_list-view" },
    },
    [
      _c(
        "div",
        {
          staticClass: "d-flex align-items-center justify-content-between mb-2",
        },
        [
          _c("h1", { staticClass: "flex-grow-1" }, [_vm._v(" Equipment ")]),
          _c(
            "ui-button",
            {
              attrs: { "data-test-id": "open_form-button" },
              on: {
                clickbutton: function ($event) {
                  _vm.isFormOpened = true
                },
              },
            },
            [_vm._v(" Add new equipment ")]
          ),
        ],
        1
      ),
      _c("FilterTableComponent", {
        staticClass: "emobg-border-1 emobg-border-color-ground",
        attrs: {
          data: _vm.equipment,
          schema: _vm.EQUIPMENT_SCHEMA,
          "row-actions": _vm.rowActions,
          "is-loading": _vm.equipmentStatus.LOADING,
          "data-test-id": "list",
        },
      }),
      _vm.isFormOpened
        ? _c("EquipmentForm", {
            attrs: {
              equipment: _vm.equipmentSelected,
              "is-preview": _vm.isPreviewMode,
              "on-success": () => _vm.getVehicleEquipment(),
            },
            on: { closeModal: _vm.closeModal },
          })
        : _vm._e(),
      _vm.isDeleteFormOpened
        ? _c("DeleteEquipment", {
            attrs: {
              equipment: _vm.equipmentSelected,
              "on-success": () => _vm.getVehicleEquipment(),
            },
            on: { closeModal: _vm.closeModal },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }