var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "GenericModalComponent",
    _vm._g(
      {
        staticClass: "EquipmentForm",
        attrs: {
          header: { isClosable: true },
          title: _vm.modalTitle,
          "data-test-id": "equipment-form",
        },
        scopedSlots: _vm._u([
          {
            key: "alerts",
            fn: function () {
              return [
                _c("StoreNotificationComponent", {
                  attrs: {
                    "store-domain": _vm.DOMAINS_MODEL.fleet.vehicleEquipment,
                    scope: _vm.EQUIPMENT_SCOPES.equipment,
                    "is-editing": _vm.isEditing,
                    element: "equipment",
                    "data-test-id": "notification",
                  },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "body",
            fn: function () {
              return [
                _c(
                  "MuiValidationWrapper",
                  {
                    staticClass: "row",
                    on: { areAllValid: (valid) => (_vm.isFormValid = valid) },
                  },
                  [
                    _vm.isPreview
                      ? [
                          _c(
                            "div",
                            { staticClass: "col-12 mb-3" },
                            [
                              _c(
                                "ContentCellComponent",
                                { attrs: { label: "Status" } },
                                [
                                  _c("ui-badge", {
                                    attrs: {
                                      text: _vm.inputs.active
                                        ? "Active"
                                        : "Inactive",
                                      color: _vm.inputs.active
                                        ? _vm.COLORS.success
                                        : _vm.GRAYSCALE.ground,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "col-md-6 mb-3" },
                            [
                              _c("ContentCellComponent", {
                                attrs: {
                                  value: _vm.upperFirst(_vm.inputs.area),
                                  label: "Area",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "col-md-6 mb-3" },
                            [
                              _c("ContentCellComponent", {
                                attrs: {
                                  value: _vm.inputs.internalName,
                                  label: "Internal name",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "col-12 mb-3" },
                            [
                              _c("TranslatableFieldComponent", {
                                attrs: {
                                  "is-input-text": "",
                                  label: "Name",
                                  disabled: "",
                                },
                                model: {
                                  value: _vm.inputs.translatedName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.inputs, "translatedName", $$v)
                                  },
                                  expression: "inputs.translatedName",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "col-12 mb-3" },
                            [
                              _c(
                                "ContentCellComponent",
                                { attrs: { label: "Picture" } },
                                [
                                  _c("ImageTemplate", {
                                    staticClass: "text-center",
                                    attrs: {
                                      url: _vm.equipment.picture,
                                      height: 200,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      : [
                          _c(
                            "div",
                            { staticClass: "col-12 mb-3" },
                            [
                              _c("ui-toggle", {
                                attrs: {
                                  value: _vm.inputs.active,
                                  text: _vm.inputs.active
                                    ? "Active"
                                    : "Inactive",
                                  label: "Status",
                                  "data-test-id": "active-toggle",
                                },
                                on: {
                                  changevalue: ({ detail }) =>
                                    (_vm.inputs.active = detail),
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "col-md-6 mb-3" },
                            [
                              _c("MuiSelect", {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: {
                                      isRequired: true,
                                    },
                                    expression:
                                      "{\n              isRequired: true,\n            }",
                                  },
                                ],
                                staticClass: "w-100",
                                attrs: {
                                  options: _vm.EQUIPMENT_AREAS,
                                  label: "Area*",
                                  name: "area",
                                },
                                model: {
                                  value: _vm.inputs.area,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.inputs, "area", $$v)
                                  },
                                  expression: "inputs.area",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "col-md-6 mb-3" },
                            [
                              _c("MuiInputText", {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: {
                                      isRequired: true,
                                    },
                                    expression:
                                      "{\n              isRequired: true,\n            }",
                                  },
                                ],
                                attrs: {
                                  label: "Internal name*",
                                  name: "internalName",
                                },
                                model: {
                                  value: _vm.inputs.internalName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.inputs, "internalName", $$v)
                                  },
                                  expression: "inputs.internalName",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "col-12 mb-3" },
                            [
                              _c("TranslatableFieldComponent", {
                                attrs: { "is-input-text": "", label: "Name" },
                                model: {
                                  value: _vm.inputs.translatedName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.inputs, "translatedName", $$v)
                                  },
                                  expression: "inputs.translatedName",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "col-12 mb-3" },
                            [
                              _c(
                                "label",
                                { staticClass: "emobg-label-1 mb-1" },
                                [_vm._v(" Picture ")]
                              ),
                              _c("DragFileComponent", {
                                attrs: {
                                  "existing-files": _vm.currentImages,
                                  "draggable-height": 200,
                                  "show-preview": "",
                                },
                                on: {
                                  change: _vm.onFilesChange,
                                  existingFileRemoved: (files) =>
                                    (_vm.currentImages = files),
                                },
                                model: {
                                  value: _vm.files,
                                  callback: function ($$v) {
                                    _vm.files = $$v
                                  },
                                  expression: "files",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                  ],
                  2
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "footer",
            fn: function () {
              return [
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex justify-content-center justify-content-sm-end align-items-center",
                  },
                  [
                    _c("CancelButton", {
                      attrs: { "data-test-id": "close_modal-button" },
                      on: { click: () => _vm.$emit("closeModal") },
                    }),
                    !_vm.isPreview
                      ? _c(
                          "ui-button",
                          {
                            staticClass: "wmin-initial",
                            attrs: {
                              disabled: _vm.hasSameValues || !_vm.isFormValid,
                              loading: _vm.equipmentStatus.LOADING,
                              "data-test-id": "save-button",
                            },
                            on: { clickbutton: _vm.equipmentRequest },
                          },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.isEditing ? "Save" : "Add") + " "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      },
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }