<script>
import get from 'lodash/get';
import { mapActions, mapState } from 'vuex';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { FilterTableComponent } from '@/components';
import { EQUIPMENT_SCHEMA } from '../const/equipment.const';
import EquipmentForm from '../components/EquipmentForm';
import DeleteEquipment from '../components/DeleteEquipment';

export default {
  name: 'EquipmentListView',
  components: {
    EquipmentForm,
    DeleteEquipment,
    FilterTableComponent,
  },
  data() {
    return {
      isFormOpened: false,
      isDeleteFormOpened: false,
      equipmentSelected: null,
      isPreviewMode: false,
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.fleet.vehicleEquipment, {
      equipment: state => state.data.data,
      equipmentStatus: state => state.STATUS,
    }),
  },
  async created() {
    this.EQUIPMENT_SCHEMA = EQUIPMENT_SCHEMA;
    this.rowActions = [
      {
        label: 'Preview equipment',
        action: equipment => {
          this.equipmentSelected = equipment;
          this.isPreviewMode = true;
          this.isFormOpened = true;
        },
      },
      {
        label: 'Edit equipment',
        action: equipment => {
          this.equipmentSelected = equipment;
          this.isFormOpened = true;
        },
      },
      {
        label: 'Delete equipment',
        labelClass: 'emobg-color-danger',
        action: equipment => {
          this.equipmentSelected = equipment;
          this.isDeleteFormOpened = true;
        },
      },
    ];

    await this.getVehicleEquipment();
  },
  methods: {
    get,
    ...mapActions(DOMAINS_MODEL.fleet.vehicleEquipment, ['getVehicleEquipment']),
    closeModal() {
      this.isFormOpened = false;
      this.isPreviewMode = false;
      this.isDeleteFormOpened = false;
      this.equipmentSelected = null;
    },
  },
};
</script>

<template>
  <div
    class="EquipmentsListView"
    data-test-id="equipments_list-view"
  >
    <div class="d-flex align-items-center justify-content-between mb-2">
      <h1 class="flex-grow-1">
        Equipment
      </h1>
      <ui-button
        data-test-id="open_form-button"
        @clickbutton="isFormOpened = true"
      >
        Add new equipment
      </ui-button>
    </div>

    <FilterTableComponent
      :data="equipment"
      :schema="EQUIPMENT_SCHEMA"
      :row-actions="rowActions"
      :is-loading="equipmentStatus.LOADING"
      data-test-id="list"
      class="emobg-border-1 emobg-border-color-ground"
    />

    <EquipmentForm
      v-if="isFormOpened"
      :equipment="equipmentSelected"
      :is-preview="isPreviewMode"
      :on-success="() => getVehicleEquipment()"
      @closeModal="closeModal"
    />

    <DeleteEquipment
      v-if="isDeleteFormOpened"
      :equipment="equipmentSelected"
      :on-success="() => getVehicleEquipment()"
      @closeModal="closeModal"
    />
  </div>
</template>
