<script>
import { base64WithoutName } from '@emobg/web-utils';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import map from 'lodash/map';
import omit from 'lodash/omit';
import upperFirst from 'lodash/upperFirst';
import { mapActions, mapState } from 'vuex';
import {
  MuiInputText,
  MuiSelect,
  MuiValidationWrapper,
  Validate,
} from '@emobg/motion-ui/v1';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import {
  CancelButton,
  ContentCellComponent,
  DragFileComponent,
  GenericModalComponent,
  ImageTemplate,
  StoreNotificationComponent,
  TranslatableFieldComponent,
} from '@/components';
import { EQUIPMENT_SCOPES } from '../store/VehicleEquipmentModule';
import { EQUIPMENT_AREAS } from '../const/equipment.const';

export default {
  name: 'EquipmentForm',
  components: {
    CancelButton,
    ContentCellComponent,
    DragFileComponent,
    GenericModalComponent,
    ImageTemplate,
    MuiInputText,
    MuiSelect,
    MuiValidationWrapper,
    StoreNotificationComponent,
    TranslatableFieldComponent,
  },
  directives: {
    Validate,
  },
  props: {
    equipment: {
      type: Object,
      default: null,
    },
    isPreview: {
      type: Boolean,
      default: false,
    },
    onSuccess: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      isFormValid: true,
      currentImages: [],
      files: [],
      inputs: {
        area: '',
        active: false,
        internalName: '',
        translatedName: {},
        picture: '',
      },
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.fleet.vehicleEquipment, {
      equipmentStatus: state => state.equipment.STATUS,
    }),
    isEditing() {
      return !!this.equipment;
    },
    modalTitle() {
      const editPreviewTitle = this.isPreview ? 'Preview equipment' : 'Edit equipment';
      return this.isEditing
        ? editPreviewTitle
        : 'Add new equipment';
    },
    hasSameValues() {
      return isEqual(this.inputs, this.originalInputs);
    },
  },
  created() {
    this.DOMAINS_MODEL = DOMAINS_MODEL;
    this.EQUIPMENT_SCOPES = EQUIPMENT_SCOPES;
    this.EQUIPMENT_AREAS = map(EQUIPMENT_AREAS, area => ({
      label: upperFirst(area),
      value: area,
    }));

    if (this.equipment) {
      this.inputs.area = this.equipment.area;
      this.inputs.active = !!this.equipment.active;
      this.inputs.internalName = this.equipment.internalName;
      this.inputs.translatedName = this.equipment.nameTranslations;
      this.currentImages = [{ name: `${this.inputs.internalName}`, src: this.equipment.picture }];
    }

    this.originalInputs = cloneDeep(this.inputs);
  },
  methods: {
    upperFirst,
    ...mapActions(DOMAINS_MODEL.fleet.vehicleEquipment, ['postVehicleEquipment', 'putVehicleEquipment']),
    async equipmentRequest() {
      const request = this.isEditing ? this.putVehicleEquipment : this.postVehicleEquipment;
      const omittedParams = [];
      if (!this.inputs.picture) {
        omittedParams.push('picture');
      }
      const data = omit(this.inputs, omittedParams);
      const dataToSend = this.isEditing ? { equipmentUuid: this.equipment.uuid, data } : data;

      await request(dataToSend);

      if (!this.equipmentStatus.ERROR) {
        const action = this.isEditing ? 'edited' : 'added';
        this.$emit('closeModal');
        this.$notify({
          message: `Vehicle equipment successfully <span class="emobg-font-weight-semibold">${action}</span>`,
          textAction: '',
        });

        this.onSuccess();
      }
    },
    onFilesChange() {
      this.inputs.picture = this.files.length ? base64WithoutName(this.files[0]) : null;
      this.currentImages = this.files.length ? [] : this.currentImages;
    },
  },
};
</script>

<template>
  <GenericModalComponent
    :header="{ isClosable: true }"
    :title="modalTitle"
    class="EquipmentForm"
    data-test-id="equipment-form"
    v-on="$listeners"
  >
    <template #alerts>
      <StoreNotificationComponent
        :store-domain="DOMAINS_MODEL.fleet.vehicleEquipment"
        :scope="EQUIPMENT_SCOPES.equipment"
        :is-editing="isEditing"
        element="equipment"
        data-test-id="notification"
      />
    </template>
    <template #body>
      <MuiValidationWrapper
        class="row"
        @areAllValid="valid => isFormValid = valid"
      >
        <template v-if="isPreview">
          <div class="col-12 mb-3">
            <ContentCellComponent label="Status">
              <ui-badge
                :text="inputs.active ? 'Active' : 'Inactive'"
                :color="inputs.active ? COLORS.success : GRAYSCALE.ground"
              />
            </ContentCellComponent>
          </div>
          <div class="col-md-6 mb-3">
            <ContentCellComponent
              :value="upperFirst(inputs.area)"
              label="Area"
            />
          </div>
          <div class="col-md-6 mb-3">
            <ContentCellComponent
              :value="inputs.internalName"
              label="Internal name"
            />
          </div>
          <div class="col-12 mb-3">
            <TranslatableFieldComponent
              v-model="inputs.translatedName"
              is-input-text
              label="Name"
              disabled
            />
          </div>
          <div class="col-12 mb-3">
            <ContentCellComponent label="Picture">
              <ImageTemplate
                :url="equipment.picture"
                :height="200"
                class="text-center"
              />
            </ContentCellComponent>
          </div>
        </template>

        <template v-else>
          <div class="col-12 mb-3">
            <ui-toggle
              :value="inputs.active"
              :text="inputs.active ? 'Active' : 'Inactive'"
              label="Status"
              data-test-id="active-toggle"
              @changevalue="({ detail }) => inputs.active = detail"
            />
          </div>
          <div class="col-md-6 mb-3">
            <MuiSelect
              v-model="inputs.area"
              v-validate="{
                isRequired: true,
              }"
              :options="EQUIPMENT_AREAS"
              label="Area*"
              name="area"
              class="w-100"
            />
          </div>
          <div class="col-md-6 mb-3">
            <MuiInputText
              v-model="inputs.internalName"
              v-validate="{
                isRequired: true,
              }"
              label="Internal name*"
              name="internalName"
            />
          </div>
          <div class="col-12 mb-3">
            <TranslatableFieldComponent
              v-model="inputs.translatedName"
              is-input-text
              label="Name"
            />
          </div>
          <div class="col-12 mb-3">
            <label class="emobg-label-1 mb-1">
              Picture
            </label>
            <DragFileComponent
              v-model="files"
              :existing-files="currentImages"
              :draggable-height="200"
              show-preview
              @change="onFilesChange"
              @existingFileRemoved="files => currentImages = files"
            />
          </div>
        </template>
      </MuiValidationWrapper>
    </template>

    <template #footer>
      <div class="d-flex justify-content-center justify-content-sm-end align-items-center">
        <CancelButton
          data-test-id="close_modal-button"
          @click="() => $emit('closeModal')"
        />

        <ui-button
          v-if="!isPreview"
          :disabled="hasSameValues || !isFormValid"
          :loading="equipmentStatus.LOADING"
          class="wmin-initial"
          data-test-id="save-button"
          @clickbutton="equipmentRequest"
        >
          {{ isEditing ? 'Save' : 'Add' }}
        </ui-button>
      </div>
    </template>
  </GenericModalComponent>
</template>
